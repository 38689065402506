import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/simulation/product/prices', config);
}

const get = async (id) => {
    return FinanceApi.get('/simulation/product/prices/' + id);
}

const store = async (data) => {
    if (data.id) {
        return FinanceApi.put('/simulation/product/prices/' + data.id, data);
    }
    return FinanceApi.post('/simulation/product/prices', data);
}

const update = async (id, formData) => {
    return FinanceApi.put('/simulation/product/prices/' + id, formData);
}

const deletePrices = async (code) => {
    return FinanceApi.delete('/simulation/product/prices/' + code);
}

const fileImport = async (data) => {
    return FinanceApi.post('/simulation/product/prices/import', data);
}

const create = async (data) => {
    return FinanceApi.post('/simulation/product/prices/create', data);
}

const created = async (config) => {
    return FinanceApi.get('/simulation/product/prices/created', config);
}

const run = async (data) => {
    return FinanceApi.post('/payment/plans/simulation', data);
}

export default {
    getAll,
    get,
    store,
    update,
    deletePrices,
    fileImport,
    create,
    run,
    created
}
